// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")
require('bootstrap/dist/js/bootstrap.bundle.min') // importando bootstrap bundle direto
require('bootstrap/dist/css/bootstrap.min.css')
require("datatables.net/js/jquery.dataTables.min")
require('datatables.net-bs4')                     // importanto data-tables
require("datatables.net-bs4/css/dataTables.bootstrap4.min.css")
require('datatables.net-buttons-bs4/js/buttons.bootstrap4.min');
require('datatables.net-buttons/js/buttons.html5.min');
require('datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css');
// require('datatables.net-buttons-bs4')
require('datatables.net-responsive-bs4')
require('datatables.net-responsive-bs4/css/responsive.bootstrap4.min.css')
require('slick-carousel/slick/slick.min')
require('slick-carousel/slick/slick.css')
require('slick-carousel/slick/slick-theme.css')
require('xlsx/dist/xlsx.full.min.js')
require('jquery-datetimepicker/jquery.datetimepicker')
require('jquery-datetimepicker/jquery.datetimepicker.css')
require('select2/dist/js/select2.full')
require('select2/dist/css/select2.min.css')
import Sortable from 'sortablejs';

import "@fortawesome/fontawesome-free/js/all";

import $ from 'jquery';
global.$ = jQuery;
require("trix")
require("@rails/actiontext")

document.addEventListener('DOMContentLoaded', () => {
  const el = document.getElementById('sortable-list');
  if (el) {
    new Sortable(el, {
      animation: 150,
      ghostClass: 'blue-background-class',
      onEnd: function (/**Event*/evt) {
        let itemEl = evt.item;  // dragged HTMLElement
        // ここでAJAXリクエストを送信
        fetch('/admin/companies/move_department', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
          },
          body: JSON.stringify({
            order: [...el.children].map((child, index) => {
              return {
                id: child.dataset.id,
                position: index + 1
              }
            })
          })
        });
      },
    });
  }
});

document.addEventListener('DOMContentLoaded', () => {
  const el = document.getElementById('sortable-list2');
  if (el) {
    new Sortable(el, {
      animation: 150,
      ghostClass: 'blue-background-class',
      onEnd: function (/**Event*/evt) {
        let itemEl = evt.item;  // dragged HTMLElement
        // ここでAJAXリクエストを送信
        fetch('/admin/companies/move_group', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
          },
          body: JSON.stringify({
            order: [...el.children].map((child, index) => {
              return {
                id: child.dataset.id,
                position: index + 1
              }
            })
          })
        });
      },
    });
  }
});
